import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import { useTranslation } from '@/hooks/translations';
import {
    DatePresentationEnum,
    IRestaurant,
    IVendorQsrOrderConfig,
    QSRPrintLayoutEnum,
    QSRPrintSizeEnum,
} from '@/views/OrdersTableView/types';
import { kDefaults } from '@/services/utils/merge';
import { useRestaurantContext } from '@/contexts/restaurant';
import React, { useEffect, useState } from 'react';
import { parseJSON } from '@/components/TicketModal/utils';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const activePrinterKey = 'qlub.printer.active';

export const parsePrinterConfig = (config: IVendorQsrOrderConfig): any => {
    const { printerSize, ...rest } = config;
    const printerSizeMapArray = parseJSON(printerSize) as any[];
    if (printerSizeMapArray?.length) {
        return {
            ...rest,
            printerSize: printerSizeMapArray?.reduce((a, { key, value }) => {
                a[key] = value;
                return a;
            }, {}),
        };
    }
    return { ...rest };
};

export const transformPrinterConfig = (config: IVendorQsrOrderConfig): any => {
    const { printerSize, ...rest } = config;
    if (printerSize) {
        return {
            ...rest,
            printerSize: JSON.stringify(
                Object.entries(printerSize || {}).map(([key, value]) => {
                    return {
                        key,
                        value,
                    };
                }),
            ),
        };
    }
    return { ...rest };
};

export const getPrinterConfig = (vendor?: IRestaurant | null): IVendorQsrOrderConfig => {
    const def: IVendorQsrOrderConfig = {
        printerPort: '8043',
        printerDeviceId: 'local_printer',
        printerEncrypt: true,
        printerAutoPrintStatus: ['pending'],
        printerDisableAutoPrint: false,
        printerLayout: [QSRPrintLayoutEnum.Kitchen, QSRPrintLayoutEnum.Cashier],
        printerSize: {
            [QSRPrintLayoutEnum.Kitchen]: QSRPrintSizeEnum.Normal,
            [QSRPrintLayoutEnum.Cashier]: QSRPrintSizeEnum.Normal,
        },
        printerImageBased: false,
        printerLang: 'en',
        printerNumberOfCopies: 1,
        vendorDatePresentation: DatePresentationEnum.Relative,
    };
    const data = kDefaults(parsePrinterConfig(vendor?.order_config || {}), def);
    return {
        ...data,
        printerAutoPrintStatus:
            typeof data.printerAutoPrintStatus === 'string'
                ? [data.printerAutoPrintStatus]
                : data.printerAutoPrintStatus,
        printerLayout: typeof data.printerLayout === 'string' ? [data.printerLayout] : data.printerLayout,
    };
};

export enum PosPrintModeEnum {
    QSR = 'qsr',
    PrinterOnly = 'printerOnly',
}

interface IProps {
    mode?: PosPrintModeEnum;
    onChange: (config: IVendorQsrOrderConfig, active: boolean) => void;
}

const QsrOrderSettings = ({ onChange, mode }: IProps) => {
    const { t } = useTranslation('common');
    const { restaurant } = useRestaurantContext();
    const [config, setConfig] = useState<IVendorQsrOrderConfig>(getPrinterConfig());
    const [active, setActive] = useState(sessionStorage.getItem(activePrinterKey) === 'true');

    useEffect(() => {
        if (!restaurant) {
            return;
        }

        setConfig(getPrinterConfig(restaurant));
    }, [restaurant]);

    useEffect(() => {
        onChange(config, active);
    }, [config]);

    useEffect(() => {
        sessionStorage.setItem(activePrinterKey, active || mode === PosPrintModeEnum.PrinterOnly ? 'true' : 'false');
    }, [active, mode]);

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{t('Printer Configuration')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2} sx={{ marginTop: '12px' }}>
                    <Grid item xs={4}>
                        <TextField
                            label={t('IP')}
                            fullWidth
                            size="small"
                            value={config.printerIP}
                            onChange={(e) => {
                                setConfig((o) => {
                                    return {
                                        ...o,
                                        printerIP: e.target.value,
                                    };
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label={t('Port')}
                            fullWidth
                            size="small"
                            value={config.printerPort}
                            onChange={(e) => {
                                setConfig((o) => {
                                    return {
                                        ...o,
                                        printerPort: e.target.value,
                                    };
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={config.printerEncrypt}
                                    onChange={(e) => {
                                        setConfig((o) => {
                                            return {
                                                ...o,
                                                printerEncrypt: Boolean(e.target.checked),
                                            };
                                        });
                                    }}
                                />
                            }
                            label={t('SSL')}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label={t('Device ID')}
                            fullWidth
                            size="small"
                            value={config.printerDeviceId}
                            onChange={(e) => {
                                setConfig((o) => {
                                    return {
                                        ...o,
                                        printerDeviceId: e.target.value,
                                    };
                                });
                            }}
                        />
                    </Grid>
                    {mode !== PosPrintModeEnum.PrinterOnly && (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={active}
                                        onChange={(e) => {
                                            setActive(e.target.checked);
                                        }}
                                    />
                                }
                                label={t('Activate the printer')}
                            />
                        </Grid>
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default QsrOrderSettings;
